<template>
  <img src="/underconstruction.jpg" alt="Construction Page"
       style="max-width: 100%;
                    height: 100vh;
                    width: auto\9; /* ie8 */">
</template>

<script>
export default {
  name: "MaintenanceMode"
}
</script>

<style scoped>

</style>
